import * as React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'Atoms';
import { Button as BaseButton, MapCancelButton } from 'Molecules';

export const Description = styled.p`
  margin: 0;
`;

export const Header = styled(Typography)`
  font-size: 1.35rem;
  display: flex;
  font-weight: bold;
  ${({ theme }) => `
    color: ${theme.colors.black[500]};
    & > svg {
      width: 2rem;
      height: 2rem;
      margin: 0 0.35rem 0 -0.3rem;
      color: ${theme.colors.green['A700']};
    }
  `}
`;

export const Label = styled(({ ...props }) => <Typography {...props} />)`
  display: flex;
  justify-content: space-around;
  align-items: center;

  ${({ isFlowStarted, theme }) =>
    isFlowStarted &&
    css`
      flex-direction: column;
      align-items: flex-start;
      color: ${theme.colors.black[500]};
    `}
`;
export const Button = styled(({ ...props }) => <BaseButton {...props} />)`
  background-color: ${({ theme }) => theme.colors.purple[500]};
  border: 0;
  border-radius: 2rem;
  width: 12rem;
  height: 3rem;
  color: ${({ theme }) => theme.colors.white[500]};
  font-size: 1rem;
  position: absolute;
  z-index: 1;

  &:hover {
    background-color: ${({ theme }) => theme.colors.purple[500]};
    color: ${({ theme }) => theme.colors.white[500]};
    border: 0;
  }

  &:focus {
    &:after {
      content: none;
    }
  }

  ${({ theme, isEmbed, bannerHeight }) => css`
    ${theme.breakpoints.up('md')} {
      ${theme.direction === 'rtl' ? 'left' : 'right'}: 1.5rem;
      top: ${isEmbed
        ? '0px'
        : `${
            Number(theme.proposals.masthead.height.replace('rem', '')) +
            1.5 +
            bannerHeight
          }rem`};
    }
  `};

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      bottom: 0.625rem;
      ${theme.direction === 'rtl' ? 'left' : 'right'}: 0.625rem;
    }
  `};

  ${({ isFlowStarted, theme, isEmbed, bannerHeight }) =>
    isFlowStarted &&
    css`
      padding: 1rem;
      width: 15rem;
      height: 5.5rem;
      transition: all 0.2s ease-out;
      background-color: ${theme.colors.white[500]};
      color: ${theme.colorMappings.brand};
      ${theme.direction === 'rtl' ? 'left' : 'right'}: 1.5rem;
      top: ${isEmbed
        ? '0px'
        : `${
            Number(theme.proposals.masthead.height.replace('rem', '')) +
            1.5 +
            bannerHeight
          }rem`};
      &:hover {
        background-color: ${({ theme }) => theme.colors.white[500]};
      }
    `};
`;
export const CancelButton = styled(({ ...props }) => (
  <MapCancelButton {...props} />
))`
  position: absolute;
  top: 4.6875rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: 0;
      top: unset;
      right: unset;
      left: 12rem;
      bottom: 3.5rem;
    }
  `};
`;
