import * as React from 'react';
import { useTranslation } from 'react-i18next';
import camelCase from 'lodash.camelcase';
import { useRouter } from 'next/router';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTheme } from 'styled-components';
import { TextInput } from 'Client/components/molecules/TextInput/TextInput';
import { DragNDropIcon, EditIcon, EditModeTrashIcon } from 'Atoms/Icons';
import { NavBarIcons } from 'Atoms/Icons/NavBar';
import { useEditModeContext } from 'Client/utils/hooks';
import { ToolTip } from 'Client/components/molecules';
import {
  EDITABLE_PAGE_TYPES,
  NAVBAR_ACTIONS,
} from 'Client/pages/edit/constants';
import { NavbarItem } from 'Shared/types/navbar';
import { ErrorStatusOrHelperText } from 'Atoms';
import InfoContainer from '../components/InfoContainer/InfoContainer';
import { IconSelect } from '../QuestionEditors/components/PriorityOptionsHandler.styles';
import {
  AccordionButtons,
  AccordionContent,
  AccordionHeader,
  AccordionLeftSide,
  AccordionRightSide,
  AddItemButton,
  DraggableContainerSlim,
  DraggableContainerSlimActive,
  EditModeButton,
  NavItemsContainer,
  PagesDropdown,
  StyledSwitch,
} from './NavBarEditor.styles';

export const NavBarEditor = ({ editablePages }) => {
  const router = useRouter();
  const theme = useTheme();
  const { t } = useTranslation('customer');
  const [{ navbarState }, { dispatchNavbar }] = useEditModeContext();
  const [errors, setErrors] = React.useState({});

  const getPageLinkByType = (type: EDITABLE_PAGE_TYPES, value: string) => {
    switch (type) {
      case EDITABLE_PAGE_TYPES.PROPOSAL:
        return `/proposals${value}/step1`;
      case EDITABLE_PAGE_TYPES.MAP:
        return `/map${value}`;
      default:
        return value;
    }
  };
  const editablePagesWithCompleteLink = editablePages.map((page) => ({
    ...page,
    value: getPageLinkByType(page.type, page.value),
  }));

  const pageOptions = [
    ...editablePagesWithCompleteLink,
    { label: 'News', value: '/news' },
  ].filter(
    (p) =>
      ![
        EDITABLE_PAGE_TYPES.DEMOGRAPHICS,
        EDITABLE_PAGE_TYPES.PREFERENCES,
        'addProposal',
      ].includes(p.type)
  );

  const iconOptions = Object.keys(NavBarIcons).map((item) => ({
    value: item,
    icon: NavBarIcons[item],
  }));

  const stateItems = navbarState[router.locale].items;

  const initialNavItems: NavbarItem[] = stateItems.length
    ? stateItems
    : [
        {
          icon: iconOptions.find((i) => i.value === 'Book').value,
          label: 'About the project',
          name: 'about',
          active: true,
          link: '/',
        },
        {
          icon: iconOptions.find((i) => i.value === 'CalendarTime').value,
          label: t('Timeline'),
          name: 'timeline',
          active: true,
          link: '/timeline',
        },
        {
          icon: iconOptions.find((i) => i.value === 'Send').value,
          label: t('Latest news'),
          name: 'news',
          active: true,
          link: '/news',
        },
        {
          icon: iconOptions.find((i) => i.value === 'Apps').value,
          label: t('All tiles'),
          name: 'tiles',
          active: true,
          link: '/proposals',
        },

        {
          icon: iconOptions.find((i) => i.value === 'UsersGroup').value,
          label: t('The team'),
          name: 'team',
          active: true,
          link: '/project-team',
        },
      ];

  const [navItems, setNavItems] = React.useState<NavbarItem[]>(initialNavItems);
  const [isEditing, setIsEditing] = React.useState(-1);

  const handleAddOption = () => {
    setNavItems([
      ...navItems,
      {
        icon: iconOptions[0].value,
        label: 'Page',
        name: '',
        active: true,
        link: '',
      },
    ]);
  };

  const handleRemoveOption = (index) => {
    setNavItems(navItems.filter((_, idx) => idx !== index));
  };

  const validators = {
    ['label']: (name: string) => {
      if (name.length < 2) {
        return { label: 'Minimum 2 characters' };
      }
      if (name.length > 12) {
        return { label: 'Maximum 12 characters' };
      }
      return { label: null };
    },
    ['icon']: (icon: string) => {
      if (!icon) {
        return { icon: 'Icon is required' };
      }
      if (navItems.filter((item) => item.icon === icon).length > 1) {
        return { icon: 'Icons must be unique' };
      }
      return { icon: null };
    },
    ['link']: (link: string) => {
      if (!link) {
        return { link: 'link is required' };
      }
      if (navItems.filter((item) => item.link === link).length > 1) {
        return { link: 'Link must be unique' };
      }
      return { link: null };
    },
  };
  const handleItemChange = (index, key, value) => {
    const newItems = navItems.map((item, idx) => {
      if (idx === index) {
        setErrors({
          ...errors,
          [index]: { ...errors[index], ...validators[key](value) },
        });
        return {
          ...item,
          [key]: value,
          ...(key === 'label' ? { name: camelCase(value) } : {}),
        };
      }
      return item;
    });
    setNavItems(newItems);
  };

  React.useEffect(() => {
    dispatchNavbar({
      type: NAVBAR_ACTIONS.UPDATE_NAVBAR_ITEMS,
      payload: {
        items: navItems,
        language: router.locale,
      },
    });
  }, [navItems]);

  const reorder = (
    list: Array<NavbarItem>,
    startIndex: number,
    endIndex: number
  ): Array<NavbarItem> => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === 0) return;

    const newOptions = reorder(
      navItems,
      result.source.index,
      result.destination.index
    );
    setNavItems(newOptions);
  };

  const ErrorItem: React.FC<{ index: number; type: string }> = ({
    index,
    type,
  }) => {
    if (isEditing !== index) return null;
    if (!errors[index]?.[type]) return null;

    return (
      <ErrorStatusOrHelperText
        status={{
          type: 'warning',
          message: errors[index][type],
        }}
      />
    );
  };

  const isProjectTeamLink = (item) => item.link === '/project-team';

  return (
    <div>
      <InfoContainer>
        <p>
          {t(
            'Customise the navigation bar. You can add up to 5 items, edit their labels, choose different icons, and activate or deactivate specific elements.'
          )}
        </p>
      </InfoContainer>
      <div>
        <h2>{t('Icons and text')}</h2>
        <div>
          <NavItemsContainer>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="cardOrder">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {navItems.map((item, index) => (
                      <Draggable
                        key={`${index}`}
                        draggableId={`${item.name}-${index}`}
                        index={index}
                        isDragDisabled={index === 0}
                      >
                        {(provided, snapshot) => {
                          const Wrapper = snapshot.isDragging
                            ? DraggableContainerSlimActive
                            : DraggableContainerSlim;

                          const isDisabled =
                            isEditing === index ||
                            index === 0 ||
                            isProjectTeamLink(item);

                          return (
                            <Wrapper
                              ref={provided.innerRef}
                              firstItem={index === 0}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <AccordionHeader isEditing={isEditing === index}>
                                <div>
                                  <AccordionLeftSide>
                                    <DragNDropIcon width={20} />
                                    {isEditing === index ? (
                                      <>
                                        <IconSelect
                                          isClearable={false}
                                          options={iconOptions}
                                          value={iconOptions.find(
                                            (i) => i.value === item.icon
                                          )}
                                          handleChange={({ value }) =>
                                            handleItemChange(
                                              index,
                                              'icon',
                                              value
                                            )
                                          }
                                        />
                                        <TextInput
                                          placeholder={t(
                                            'The label displayed on the item'
                                          )}
                                          value={item.label}
                                          onChange={(e) => {
                                            handleItemChange(
                                              index,
                                              'label',
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {NavBarIcons[item.icon]}
                                        {item.label}
                                      </>
                                    )}
                                  </AccordionLeftSide>

                                  <AccordionRightSide>
                                    <EditIcon
                                      color={
                                        isEditing === index || index === 0
                                          ? theme['colorMappings']
                                              .disabledButtonBackground
                                          : theme['colorMappings']
                                              .greyTextDarker
                                      }
                                      onClick={() =>
                                        index !== 0 &&
                                        setIsEditing((oldIndex) =>
                                          oldIndex === index ? -1 : index
                                        )
                                      }
                                    />
                                    {isDisabled ? (
                                      <ToolTip
                                        startPositionHorizontalMutation={-150}
                                        width={12}
                                        hoverableElement={
                                          <StyledSwitch
                                            disabled={isDisabled}
                                            checked={item.active}
                                            colorMapping="CommonplaceBrand"
                                            onChange={() =>
                                              handleItemChange(
                                                index,
                                                'active',
                                                !item.active
                                              )
                                            }
                                          />
                                        }
                                      >
                                        <span>
                                          {t('You can’t hide this section.')}
                                        </span>
                                      </ToolTip>
                                    ) : (
                                      <StyledSwitch
                                        disabled={isDisabled}
                                        checked={item.active}
                                        colorMapping="CommonplaceBrand"
                                        onChange={() =>
                                          handleItemChange(
                                            index,
                                            'active',
                                            !item.active
                                          )
                                        }
                                      />
                                    )}
                                  </AccordionRightSide>
                                </div>
                                <ErrorItem index={index} type="label" />
                                <ErrorItem index={index} type="icon" />
                              </AccordionHeader>
                              {isEditing === index && (
                                <AccordionContent>
                                  <div>
                                    <h3>{t('Link page')}</h3>
                                    <PagesDropdown
                                      id="pages-dropdown"
                                      data-testid="page-options-dropdown"
                                      name="pages-dropdown"
                                      options={pageOptions}
                                      value={pageOptions.find(
                                        (opt) => opt.value == item?.link
                                      )}
                                      width={'100%'}
                                      placeholder={t('Select a page')}
                                      handleChange={({ value }) => {
                                        handleItemChange(index, 'link', value);
                                      }}
                                      isDisabled={isProjectTeamLink(item)}
                                    />
                                    <ErrorItem index={index} type="link" />
                                  </div>
                                  <AccordionButtons>
                                    <EditModeButton
                                      onClick={() => handleRemoveOption(index)}
                                      disabled={isProjectTeamLink(item)}
                                    >
                                      <EditModeTrashIcon /> {t('Delete')}
                                    </EditModeButton>
                                    <div>
                                      <EditModeButton
                                        inverse
                                        onClick={() =>
                                          setIsEditing((oldIndex) =>
                                            oldIndex === index ? -1 : index
                                          )
                                        }
                                      >
                                        {t('Done')}
                                      </EditModeButton>
                                    </div>
                                  </AccordionButtons>
                                </AccordionContent>
                              )}
                            </Wrapper>
                          );
                        }}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <AddItemButton
              inverse
              onClick={handleAddOption}
              disabled={navItems.length == 5}
            >
              {t('Add navigation item')}
            </AddItemButton>
          </NavItemsContainer>
        </div>
      </div>
    </div>
  );
};
