import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import shpjs from 'shpjs';
import GeoJSON, { GeoJSONPoint } from 'ol/format/GeoJSON';
import { savePolygon } from 'Client/services/geolytix';
import { useProject, useMap } from 'Client/utils/hooks';
import { useMapFeatureForm } from 'Client/utils/hooks/useMapFeatureForm';
import { MapProjection, Xyz } from 'Shared/types/map';
import { UploadIcon } from 'Atoms/Icons';
import { AddFeaturePanel } from '../AddFeaturePanel';
import {
  PlusIcon,
  OpenButton,
  Menu,
  MenuItem,
  CircleIcon,
  PolygonIcon,
  MarkerIcon,
  InvisbleInput,
} from './AddFeatureButton.styles';

const AddFeatureButton = (): JSX.Element => {
  const {
    state: { proposal, features, ...state },
    dispatch,
  } = useMap();
  const xyz = state.xyz as Xyz;
  const { t } = useTranslation('customer');
  const project = useProject();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [featurePanelOpen, setFeaturePanelOpen] = React.useState(false);
  const [featureType, setFeatureType] = React.useState<
    null | 'point' | 'polygon' | 'circle'
  >(null);

  const [drawnPolygon, setDrawnPolygon] = React.useState(null);
  const [isLandmark, setIsLandmark] = React.useState(false);
  const [featureLayer, setFeatureLayer] = React.useState(null);
  const inputFileRef = React.useRef(undefined);

  const {
    description,
    imageUrl,
    title,
    hoverablePopup,
    proposal: selectedProposal,
    isPlanningApp,
    landmark,
  } = useMapFeatureForm();

  React.useEffect(() => {
    setFeatureLayer(
      new global.ol.layer.Vector({
        map: xyz.map,
        source: new global.ol.source.Vector({
          projection: `EPSG:${xyz.layers.list.Custom.srid}`,
        }),
        style: xyz.mapview.layer.styleFunction(xyz.layers.list.Custom),
      })
    );
  }, [xyz]);

  const drawStyle = [
    new global.ol.style.Style({
      image: new global.ol.style.Circle({
        stroke: new global.ol.style.Stroke({
          color: '#3399CC',
          width: 1.25,
        }),
        radius: 5,
      }),
      stroke: new global.ol.style.Stroke({
        color: '#3399CC',
        width: 1.25,
      }),
    }),
    new global.ol.style.Style({
      image: new global.ol.style.Circle({
        radius: 5,
        fill: new global.ol.style.Fill({
          color: '#eee',
        }),
        stroke: new global.ol.style.Stroke({
          color: '#3399CC',
          width: 1.25,
        }),
      }),
      geometry: xyz.utils.verticeGeoms,
    }),
  ];

  const handleUploadClick = () => {
    if (inputFileRef.current !== undefined) {
      inputFileRef.current.click();
    }
  };

  const getFeatures = (geojson) => {
    if (Array.isArray(geojson)) {
      const features = [];
      geojson.forEach((g) => {
        g?.features
          ?.map(({ geometry }) => ({
            type: geometry.type,
            coordinates: geometry.coordinates,
          }))
          .forEach((feature) => {
            features.push(feature);
          });
      });
      return features;
    } else {
      return geojson?.features?.map(({ geometry }) => ({
        type: geometry.type,
        coordinates: geometry.coordinates,
      }));
    }
  };

  const isGeojson = (file: File) => {
    const ext = file.name.split('.').pop();
    return ['geojson', 'json'].includes(ext);
  };

  const readShapefile = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = async (evt) => {
        try {
          const arrayBuffer = evt.target.result;
          const geojson = await shpjs(arrayBuffer);
          const features = getFeatures(geojson);
          resolve(features);
        } catch (err) {
          reject(err);
        }
      };
    });
  };

  const readGeojson = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = async (evt) => {
        try {
          const geojson = JSON.parse(evt.target.result as string);
          resolve(getFeatures(geojson));
        } catch (err) {
          reject(err);
        }
      };
    });
  };

  const uploadShapeFile = async (ev) => {
    if (ev.target.files.length <= 0) {
      return;
    }
    const file = ev.target.files[0];
    const features = await (isGeojson(file)
      ? readGeojson(file)
      : readShapefile(file));
    try {
      await fetch(`/api/map/boundary?pageId=${proposal.pageId}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ features }),
      });
      dispatch({
        type: 'RELOAD_LAYER',
        payload: { layer: 'Custom 4258' },
      });
    } catch (err) {
      alert(t('Invalid file'));
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawLandmark = () => {
    setIsLandmark(true);
    handleDrawPoint();
  };

  const handleDrawPoint = () => {
    try {
      const onEndDrawPoint = async (event) => {
        event.feature.setStyle(drawStyle);

        const featureGeometry = event.feature.getGeometry();

        const geoJson = new GeoJSON().writeGeometryObject(featureGeometry, {
          dataProjection: MapProjection.WEB_MERCATOR,
          featureProjection: MapProjection.WEB_MERCATOR,
        }) as GeoJSONPoint;

        setDrawnPolygon({
          geometry: geoJson,
          xyz,
          layer: 'Custom',
          pageId: proposal.pageId,
          project: project._id,
          language: 'en-GB',
        });

        setFeatureType('point');
        setFeaturePanelOpen(true);

        featureLayer.getSource().clear();
      };

      const draw = new global.ol.interaction.Draw({
        source: featureLayer.getSource(),
        type: 'Point',
        style: drawStyle,
      });

      xyz.map.addInteraction(draw);

      draw.on('drawend', onEndDrawPoint);

      xyz.mapview.node.style.cursor = 'crosshair';
    } catch (err) {
      captureException(
        `Error in handleDrawPoint @ AddFeatureButton.tsx: ${err}`
      );
    }
    handleClose();
  };

  const handleDrawCircle = () => {
    try {
      const onEndDrawCircle = async (event) => {
        event.feature.setStyle(drawStyle);

        const featureGeometry = event.feature.getGeometry();

        const geoJson = new GeoJSON().writeGeometryObject(featureGeometry, {
          dataProjection: MapProjection.WEB_MERCATOR,
          featureProjection: MapProjection.WEB_MERCATOR,
        }) as GeoJSONPoint;

        setDrawnPolygon({
          geometry: geoJson,
          xyz,
          layer: 'Custom',
          pageId: proposal.pageId,
          project: project._id,
          language: 'en-GB',
        });

        setFeatureType('circle');
        setFeaturePanelOpen(true);

        featureLayer.getSource().clear();
      };

      const geometryFunction = global.ol?.interaction?.Draw
        ? global.ol.interaction.Draw.createRegularPolygon(99)
        : undefined;

      const draw = new global.ol.interaction.Draw({
        source: featureLayer.getSource(),
        geometryFunction: geometryFunction,
        type: 'Circle',
        style: drawStyle,
      });

      xyz.map.addInteraction(draw);

      draw.on('drawend', onEndDrawCircle);

      xyz.mapview.node.style.cursor = 'crosshair';
    } catch (err) {
      captureException(
        `Error in handleDrawCircle @ AddFeatureButton.tsx: ${err}`
      );
    }
    handleClose();
  };

  const handleDrawPolygon = () => {
    try {
      const onEndDrawPolygon = async (event) => {
        event.feature.setStyle(drawStyle);

        const featureGeometry = event.feature.getGeometry();

        const geoJson = new GeoJSON().writeGeometryObject(featureGeometry, {
          dataProjection: MapProjection.WEB_MERCATOR,
          featureProjection: MapProjection.WEB_MERCATOR,
        }) as GeoJSONPoint;

        setDrawnPolygon({
          geometry: geoJson,
          xyz,
          layer: 'Custom',
          pageId: proposal.pageId,
          project: project._id,
          language: 'en-GB',
        });

        setFeatureType('polygon');
        setFeaturePanelOpen(true);

        featureLayer.getSource().clear();
      };

      const draw = new global.ol.interaction.Draw({
        source: featureLayer.getSource(),
        type: 'Polygon',
        style: drawStyle,
      });

      xyz.map.addInteraction(draw);

      draw.on('drawend', onEndDrawPolygon);

      xyz.mapview.node.style.cursor = 'crosshair';
    } catch (err) {
      captureException(
        `Error in handleDrawPolygon @ AddFeatureButton.tsx: ${err}`
      );
    }

    handleClose();
  };

  const encodeSVG = (svgString: string) => {
    const symbols = /[\r\n%#()<>?[\\\]^`{|}]/g;
    if (svgString.search(`"`) > 0) {
      svgString = svgString.replace(/"/g, `'`);
    }
    svgString = svgString.replace(/>\s{1,}</g, `><`);
    svgString = svgString.replace(/\s{2,}/g, ` `);
    return svgString.replace(symbols, encodeURIComponent).replace(/'/g, `''`);
  };

  const handleSaveDrawing = async () => {
    try {
      drawnPolygon.properties = {
        metaData: {
          description: description.replace(/'/g, `''`),
          imageUrl,
          title: title.replace(/'/g, `''`),
          hoverablePopup,
          proposalId: selectedProposal?.id,
          isPlanningApp,
        },
      };

      if (isLandmark) {
        drawnPolygon.properties.metaData.svg = `data:image/svg+xml,${encodeSVG(
          landmark
        )}`;

        setIsLandmark(false);
      }

      const { layer } = drawnPolygon;
      const featureId = await savePolygon(drawnPolygon);

      setFeaturePanelOpen(false);

      const newFeatures = { ...features };
      newFeatures[layer].push({
        type: 'Features',
        geometry: drawnPolygon.geometry,
        id: featureId,
        properties: { metadata: drawnPolygon?.properties?.metaData || {} },
      });
      newFeatures[layer].sort((a, b) => a.id - b.id);
      const sortedFeatures = { ...newFeatures };

      xyz.map.getInteractions().forEach((interaction) => {
        if (interaction.getListeners('drawend')) {
          interaction.setActive(false);
        }
      });

      featureLayer.getSource().clear();

      xyz.mapview.node.style.cursor = 'default';

      xyz.layers.list.Custom.reload();
      dispatch({
        type: 'SET_FEATURES',
        payload: { features: sortedFeatures },
      });
    } catch (err) {
      captureException(
        `Error in handleSaveDrawing @ AddFeatureButton.tsx: ${err}`
      );
    }
  };

  const handleCancelDrawing = () => {
    xyz.map.getInteractions().forEach((interaction) => {
      if (interaction.getListeners('drawend')) {
        interaction.setActive(false);
      }
    });

    setFeaturePanelOpen(false);

    if (isLandmark) setIsLandmark(false);

    featureLayer.getSource().clear();

    xyz.mapview.node.style.cursor = 'default';
  };

  return (
    <>
      <OpenButton
        aria-controls="feature-menu"
        data-testid="open-button"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <PlusIcon />
        {t('Add feature')}
      </OpenButton>
      <Menu
        id="feature-menu"
        data-testid="feature-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem data-testid="list-item" onClick={handleDrawPoint}>
          <MarkerIcon color="#5296e6" />
          {t('Add point')}
        </MenuItem>
        <MenuItem data-testid="list-item" onClick={handleDrawCircle}>
          <CircleIcon color="#5296e6" />
          {t('Add radius circle')}
        </MenuItem>
        <MenuItem data-testid="list-item" onClick={handleDrawLandmark}>
          <MarkerIcon color="#5296e6" />
          {t('Add landmark')}
        </MenuItem>
        <MenuItem data-testid="list-item" onClick={handleDrawPolygon}>
          <PolygonIcon color="#5296e6" />
          {t('Draw polygon')}
        </MenuItem>
        <MenuItem data-testid="list-item" onClick={handleUploadClick}>
          <UploadIcon color="#5296e6" />
          {t('Upload boundary file')}

          <InvisbleInput
            type="file"
            onChange={uploadShapeFile}
            ref={inputFileRef}
          />
        </MenuItem>
      </Menu>

      {featurePanelOpen && (
        <AddFeaturePanel
          handleSaveDrawing={handleSaveDrawing}
          handleCancelDrawing={handleCancelDrawing}
          featureType={featureType}
          isLandmark={isLandmark}
        />
      )}
    </>
  );
};

export { AddFeatureButton };
